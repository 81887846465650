import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import "react-dropdown/style.css";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getWebsiteInfo } from "../../slices/websites";

const LANGS = [
  {
    code: "fr",
    name: "Français",
    country_code: "fr",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
];

const currentLanguageCode = cookies.get("i18next") || "fr";

function DropDown() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { websiteID, websiteInfo } = useSelector((state) => state.websites);
  const [language, setLanguage] = useState(currentLanguageCode);
  const handleChange = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  useEffect(() => {
    if (websiteID && Object.keys(websiteInfo).length === 0) {
      dispatch(getWebsiteInfo(websiteID));
    }
  }, [dispatch, websiteID, websiteInfo]);

  const languagesList =
    websiteInfo?.languages?.length > 0 ? websiteInfo?.languages : LANGS;
  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120 }} className="test">
        <Select
          defaultValue={language}
          value={language}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {languagesList.map((lng) => (
            <MenuItem
              key={lng.code}
              value={lng.code}
              className="menu-item-language"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: `${websiteInfo?.color} !important`,
                },
                "&.Mui-selected:hover": {
                  backgroundColor: `${websiteInfo?.color} !important`,
                },
              }}
              onClick={() => {
                setLanguage(lng.code);
                i18n.changeLanguage(lng.code);
              }}
            >
              {lng.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default DropDown;
