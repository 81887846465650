import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import arTranslation from "./locales/ar/translation.json";
import frTranslation from "./locales/fr/translation.json";
import enTranslation from "./locales/en/translation.json";
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    // fallbackLng: "en",
    debug: true,
    detection: {
      order: ["cookie", "htmlTag"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: enTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      ar: {
        translation: arTranslation,
      },
    },
  });

export default i18n;
