import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import CssBaseLine from "@mui/material/CssBaseline";
import routes, { RenderRoutes } from "./routes";
import "./i18n";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const LANGUAGES = [
  {
    code: "fr",
    name: "Français",
    country_code: "fr",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
  {
    code: "de",
    name: "Deutsch",
    country_code: "de",
  },
  {
    code: "es",
    name: "Español",
    country_code: "es",
  },
];

function App() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = LANGUAGES.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage?.dir || "ltr";
    document.title = t("مكتب المساعدة");
  }, [currentLanguage, t]);
  return (
    <>
      <CssBaseLine />
      <BrowserRouter>
        <RenderRoutes routes={routes} />
      </BrowserRouter>
    </>
  );
}

export default App;
